<template>
  <div class="row">
    <div class="col-12">
      <template>
        <component
          :is="component"
          :evalTask.sync="evalTask"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'compliance-eval-table-safe',
  data() {
    return {
      editable: true,
      evalTask: {
        lawRegisterItemTypeCd: 'LRITC00001', // 안전보건 : LRITC00002, 환경 : LRITC00001
      },
      component: null,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.component = () => import('@/pages/sai/law/evalTable/complianceEvalTable.vue');
    },
    reset() {
      Object.assign(this.$data.evalTask, this.$options.data().evalTask);
    },
  }
};
</script>